<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25 social-only"
      style="animation-delay: 0s;">
        <stats-card data-background-color="blue">
          <template slot="header">
            <i class="fab fa-twitter"></i>
          </template>

          <template slot="content">
            <p class="category">
              <a
                class="social-link"
                href="https://twitter.com/1uerkbtxCnfSkKp"
                target="_blank"
                style="color: inherit; font-size: 18px;"
              >click to go</a>
            </p>
            <h3 style="color: #26c6da">My twitter</h3>
          </template>

          <template slot="footer">
            <div class="stats">https://twitter.com/1uerkbtxCnfSkKp</div>
          </template>
        </stats-card>
      </div>

      <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25 social-only" style="animation-delay: 1s;">
        <stats-card data-background-color="purple">
          <template slot="header">
            <i class="fab fa-facebook"></i>
          </template>

          <template slot="content">
            <p class="category">
              <a
                class="social-link"
                href="https://www.facebook.com/profile.php?id=100029426192587"
                target="_blank"
                style="color: inherit; font-size: 18px;"
              >click to go</a>
            </p>
            <h3 style="color: #ab47bc">My facebook</h3>
          </template>

          <template slot="footer">
            <div class="stats">id=100029426192587</div>
          </template>
        </stats-card>
      </div>

      <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25 social-only" style="animation-delay: 2s;">
        <stats-card data-background-color="blue">
          <template slot="header">
            <i class="fab fa-vk"></i>
          </template>

          <template slot="content">
            <p class="category">
              <a
                class="social-link"
                href="https://vk.com/id281239032"
                target="_blank"
                style="color: inherit; font-size: 18px;"
              >click to go</a>
            </p>
            <h3 style="color: #26c6da">My vk</h3>
          </template>

          <template slot="footer">
            <div class="stats">https://vk.com/id281239032</div>
          </template>
        </stats-card>
      </div>

      <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25 social-only" style="animation-delay: 3s;">
        <stats-card data-background-color="purple">
          <template slot="header">
            <i class="fas fa-question-circle"></i>
          </template>

          <template slot="content">
            <p class="category">
              <a
                class="social-link"
                href="https://tapid.pro/nichromedev"
                target="_blank"
                style="color: inherit; font-size: 18px;"
              >click to go</a>
            </p>
            <h3 style="color: #ab47bc">My tapid.pro</h3>
          </template>

          <template slot="footer">
            <div class="stats">https://tapid.pro/nichromedev</div>
          </template>
        </stats-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  StatsCard
  // ChartCard,
  // NavTabsCard,
  // NavTabsTable,
  // OrderedTable
} from "@/components";

export default {
  components: {
    StatsCard
    // ChartCard,
    // NavTabsCard,
    // NavTabsTable,
    // OrderedTable
  },
  data() {
    return {
      dailySalesChart: {
        data: {
          labels: ["M", "T", "W", "T", "F", "S", "S"],
          series: [[12, 17, 7, 17, 23, 18, 38]]
        },
        options: {
          lineSmooth: this.$Chartist.Interpolation.cardinal({
            tension: 0
          }),
          low: 0,
          high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          }
        }
      },
      dataCompletedTasksChart: {
        data: {
          labels: ["12am", "3pm", "6pm", "9pm", "12pm", "3am", "6am", "9am"],
          series: [[230, 750, 450, 300, 280, 240, 200, 190]]
        },

        options: {
          lineSmooth: this.$Chartist.Interpolation.cardinal({
            tension: 0
          }),
          low: 0,
          high: 1000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          }
        }
      },
      emailsSubscriptionChart: {
        data: {
          labels: [
            "Ja",
            "Fe",
            "Ma",
            "Ap",
            "Mai",
            "Ju",
            "Jul",
            "Au",
            "Se",
            "Oc",
            "No",
            "De"
          ],
          series: [[542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895]]
        },
        options: {
          axisX: {
            showGrid: false
          },
          low: 0,
          high: 1000,
          chartPadding: {
            top: 0,
            right: 5,
            bottom: 0,
            left: 0
          }
        },
        responsiveOptions: [
          [
            "screen and (max-width: 640px)",
            {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc: function(value) {
                  return value[0];
                }
              }
            }
          ]
        ]
      }
    };
  }
};
</script>

<style>
.social-link:hover {
  color: #26c6da !important;
}
.md-layout-item.social-only {
  opacity: 0;
  animation-name: fading;
  animation-duration: 0.75s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}

@keyframes fading {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
