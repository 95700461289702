<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">

    <side-bar
      :sidebar-item-color="sidebarBackground"
      :sidebar-background-image="sidebarBackgroundImage"
    >
      <mobile-menu slot="content"></mobile-menu>
      <sidebar-link to="/aboutme">
        <md-icon>face</md-icon>
        <p>About me</p>
      </sidebar-link>
      <sidebar-link to="/skills">
        <md-icon>fingerprint</md-icon>
        <p>Skills</p>
      </sidebar-link>
      <sidebar-link to="/portfolio">
        <md-icon>book</md-icon>
        <p>Portfolio</p>
      </sidebar-link>
      <sidebar-link to="/contacts">
        <md-icon>assignment_ind</md-icon>
        <p>Contacts</p>
      </sidebar-link>
      <sidebar-link to="/blog">
        <md-icon>assignment</md-icon>
        <p>Blog</p>
      </sidebar-link>
      <sidebar-link to="/icons">
        <md-icon>stars</md-icon>
        <p>Icons</p>
      </sidebar-link>
    </side-bar>

    <div class="main-panel">
      <top-navbar></top-navbar>

      <fixed-plugin
        :color.sync="sidebarBackground"
        :image.sync="sidebarBackgroundImage"
      >
      </fixed-plugin>

      <dashboard-content> </dashboard-content>

      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>

<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "@/pages/Layout/MobileMenu.vue";
import FixedPlugin from "./Extra/FixedPlugin.vue";

export default {
  components: {
    TopNavbar,
    DashboardContent,
    ContentFooter,
    MobileMenu,
    FixedPlugin
  },
  data() {
    return {
      sidebarBackground: "blue",
      sidebarBackgroundImage: require("@/assets/img/sidebar-1.jpg")
    };
  }
};
</script>
