<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
        <md-card class="md-card-plain">
          <md-card-header data-background-color="purple">
            <h4 class="title">
              I have nothing to post yet or i'm just lazy...
            </h4>
          </md-card-header>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
//import { SimpleTable, OrderedTable } from "@/components";

export default {
  props: {
    poster1: {
      type: String,
      default: require("@/assets/img/poster1.png")
    },
    poster2: {
      type: String,
      default: require("@/assets/img/poster2.png")
    },
    poster3: {
      type: String,
      default: require("@/assets/img/poster3.png")
    },
    posterMob1: {
      type: String,
      default: require("@/assets/img/poster1Mob.png")
    },
    posterMob2: {
      type: String,
      default: require("@/assets/img/poster2Mob.png")
    },
    posterMob3: {
      type: String,
      default: require("@/assets/img/poster3Mob.png")
    }
  }
  // components: {
  //   OrderedTable,
  //   SimpleTable
  // }
};
</script>

<style>
@media (min-width: 400px) {
  .img.img-poster {
    display: block;
  }
  .img.img-poster-mobile {
    display: none;
  }
}
@media (max-width: 399px) {
  .img.img-poster {
    display: none;
  }
  .img.img-poster-mobile {
    display: block;
  }
}
</style>
