<template>
  <footer class="footer">
    <div class="container">
      <nav>
        <ul>
          <li>
            <a href="https://tapid.pro/nichromedev" target="_blank">My tapid.pro</a>
          </li>
        </ul>
      </nav>
      <div class="copyright text-center">
        &copy; {{ new Date().getFullYear() }}
        <i class="fa fa-heart heart"></i> To create this site I used vue, vue material, icon material design and DEVICON
      </div>
    </div>
  </footer>
</template>

<script>
export default {};
</script>
