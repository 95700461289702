<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
        <md-card>
          <md-card-header data-background-color="orange">
            <h4 class="title">
              <a
                href="https://star-chat.vercel.app/#/public/"
                target="_blank"
              >Star chat</a>
            </h4>
            <p class="category">
              It's amazing useful social network with dialogs and channels and saved recources,
              it can be use in any platform (ios, android, desktop and web) thanks to PWA technology
            </p>
          </md-card-header>
          <md-card-content>
            <!-- <simple-table table-header-color="blue"></simple-table> -->
            <img class="img img-poster" :src="poster4" />
            <img class="img img-poster-mobile" :src="posterMob4" />
          </md-card-content>
        </md-card>
      </div>

      <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
        <md-card>
          <md-card-header data-background-color="purple">
            <h4 class="title">
              <a
                href="https://mathcalc.online/math.php?ss=algebra&fc=equations&sc=linear_equation_system_gauss_method"
                target="_blank"
              >MathCalc online</a>
            </h4>
            <p class="category">
              Site created in php to help to study math. It completely supports SPA technology. Any links doesn't reload application via AJAX technology.
              Also any external valid links lead to existing php file.
            </p>
          </md-card-header>
          <md-card-content>
            <!-- <simple-table table-header-color="blue"></simple-table> -->
            <img class="img img-poster" :src="poster1" />
            <img class="img img-poster-mobile" :src="posterMob1" />
          </md-card-content>
        </md-card>
      </div>

      <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
        <md-card>
          <md-card-header data-background-color="blue">
            <h4 class="title">
              <a href="https://18izhvent.ru/" target="_blank">Business React Application</a>
            </h4>
            <p
              class="category"
            >Site created in react as serverless aplication, but it supports sendmailer on client side.</p>
          </md-card-header>
          <md-card-content>
            <!-- <ordered-table></ordered-table> -->
            <img class="img img-poster" :src="poster2" />
            <img class="img img-poster-mobile" :src="posterMob2" />
          </md-card-content>
        </md-card>
      </div>

      <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
        <md-card>
          <md-card-header data-background-color="orange">
            <h4 class="title">
              <a
                href="https://energy-company-client.vercel.app/"
                target="_blank"
              >Business Next Apollo Application</a>
            </h4>
            <p
              class="category"
            >Site created in next (SSR) with ApolloServer (Graphql) on server side, it supports admin and ordinary user personal office and file exchange system.</p>
          </md-card-header>
          <md-card-content>
            <!-- <ordered-table></ordered-table> -->
            <img class="img img-poster" :src="poster3" />
            <img class="img img-poster-mobile" :src="posterMob3" />
          </md-card-content>
        </md-card>
      </div>

      <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
        <md-card class="md-card-plain">
          <md-card-header data-background-color="green">
            <h4 class="title">
              And some other complex and simple projects...
            </h4>
          </md-card-header>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
//import { SimpleTable, OrderedTable } from "@/components";

export default {
  props: {
    poster1: {
      type: String,
      default: require("@/assets/img/poster1.png")
    },
    poster2: {
      type: String,
      default: require("@/assets/img/poster2.png")
    },
    poster3: {
      type: String,
      default: require("@/assets/img/poster3.png")
    },
    poster4: {
      type: String,
      default: require("@/assets/img/poster4.png")
    },
    posterMob1: {
      type: String,
      default: require("@/assets/img/poster1Mob.png")
    },
    posterMob2: {
      type: String,
      default: require("@/assets/img/poster2Mob.png")
    },
    posterMob3: {
      type: String,
      default: require("@/assets/img/poster3Mob.png")
    },
    posterMob4: {
      type: String,
      default: require("@/assets/img/poster4Mob.png")
    }
  }
  // components: {
  //   OrderedTable,
  //   SimpleTable
  // }
};
</script>

<style>
@media (min-width: 400px) {
  .img.img-poster {
    display: block;
  }
  .img.img-poster-mobile {
    display: none;
  }
}
@media (max-width: 399px) {
  .img.img-poster {
    display: none;
  }
  .img.img-poster-mobile {
    display: block;
  }
}
</style>
