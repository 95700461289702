<template>
  <md-card class="md-card-profile">
    <div :class="[{ rotate: isActive }, 'md-card-avatar', 'rotate-only']">
      <img class="img" :src="cardUserImage" loading="lazy" />
    </div>

    <md-card-content>
      <h6 class="category text-gray">{{ profession }}</h6>
      <h4 class="card-title">{{ name }}</h4>
      <blockquote>
        <i>&bdquo;With great power comes great responsibility&rdquo;</i>
      </blockquote>
      <p class="card-description">
        Hello everybody. I'm senior TS developer and I'm so lucky to see you
        around. I love math science, programming and design. I also dream to get
        a Ph.D. That's all I wanted to tell you, so now enjoy your presence.
      </p>
      <md-button @click="beginRotate" class="md-round md-info"
        >click and see</md-button
      >
    </md-card-content>
  </md-card>
</template>

<script>
export default {
  name: "user-card",
  props: {
    cardUserImage: {
      type: String,
      default: require("@/assets/img/faces/my-face.jpg")
    },
    name: {
      type: String,
      default: "Valiev Ruslan"
    },
    profession: {
      type: String,
      default: "Full stack developer and designer"
    },
    isActive: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    beginRotate: function() {
      this.isActive = true;
      setTimeout(() => (this.isActive = false), 1000);
    }
  },
  data() {
    return {};
  }
};
</script>

<style>
.md-card-avatar.rotate-only {
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}
.md-card-avatar.rotate-only.rotate {
  animation-name: rotate;
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
