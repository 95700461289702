<template>
  <div class="content">
      <!-- <div class="md-layout-item md-medium-size-100 md-size-66">
        <edit-profile-form data-background-color="green"> </edit-profile-form>
      </div> -->
      <!-- <div class="md-layout-item md-medium-size-100 md-size-50">
        <user-card> </user-card>
      </div> -->
      <skill-cards> </skill-cards>
  </div>
</template>

<script>
import {SkillCards} from "@/pages";

export default {
  components: {
    SkillCards
  }
};
</script>
