import DashboardLayout from "@/pages/Layout/DashboardLayout.vue";

import Dashboard from "@/pages/Dashboard.vue";
import UserProfile from "@/pages/UserProfile.vue";
import SkillList from "@/pages/SkillList.vue";
import TableList from "@/pages/TableList.vue";
import Blog from "@/pages/Blog.vue";
//import Typography from "@/pages/Typography.vue";
import Icons from "@/pages/Icons.vue";
//import Maps from "@/pages/Maps.vue";
//import Notifications from "@/pages/Notifications.vue";
//import UpgradeToPRO from "@/pages/UpgradeToPRO.vue";

const routes = [
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/aboutme",
    children: [
      {
        path: "aboutme",
        name: "About me",
        component: UserProfile
      },
      {
        path: "skills",
        name: "Skills",
        component: SkillList
      },
      {
        path: "portfolio",
        name: "Portfolio",
        component: TableList
      },
      {
        path: "contacts",
        name: "Contacts",
        component: Dashboard
      },
      {
        path: "blog",
        name: "Blog",
        component: Blog
      },
      {
        path: "icons",
        name: "Icons",
        component: Icons
      }
    ]
  }
];

export default routes;
